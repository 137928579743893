import { Alert, AlertTitle, Link } from '@mui/material'

const NoLongerAccessible = ({ type }: { type: 'sign-in' | 'sign-up' }) => {
  const isSignIn = type === 'sign-in'
  return (
    <Alert
      severity="error"
      style={{
        border: '1px solid #F55',
        borderRadius: 8,
        fontSize: 14,
        fontWeight: 500,
        color: '#010417'
      }}
    >
      <AlertTitle
        style={{
          fontWeight: 700,
          color: '#010417',
          fontSize: 14
        }}
      >
        {isSignIn
          ? 'Public access to Archer has been discontinued. '
          : 'Archer is no longer available to the public and is no longer accepting new user registrations.'}
      </AlertTitle>
      {isSignIn ? 'Your account no longer has access. ' : ''}For more information, please visit{' '}
      <Link href="https://helloarcher.io/delisting" target="_blank">
        helloarcher.io/delisting
      </Link>
    </Alert>
  )
}

export default NoLongerAccessible

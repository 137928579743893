/* eslint-disable @typescript-eslint/ban-ts-comment */
import { trackEvent, usePageTracker } from '@/analytics'
import NoLongerAccessible from '@/components/Alert/NoLongerAccessible'
import SunsetWarning from '@/components/Alert/SunsetWarning'
import { LoadingButton } from '@/components/common/Form/LoadingButton'
import useRedirectToQueryParam from '@/hooks/useRedirectToQueryParam'
import { useAuth, useSignIn } from '@/reducers/user'
import { signIn } from '@/services/auth'
import { formatError, ServerError } from '@/utils/errors/utils'
import { emailValidator } from '@/utils/validators'
import { yupResolver } from '@hookform/resolvers/yup'
import { Alert, Typography, useTheme } from '@mui/material'
import { AxiosError } from 'axios'
import { get } from 'lodash'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { object } from 'yup'
import FormInput from '../../components/Form/Input'
import { SignUpPageLayout } from './BaseLayout'
import DeleteAlert from './DeleteAlert'
import WelcomeSideScreen from './sideScreen/Welcome'

interface FormValues {
  email: string
  password: string
}

const schema = object({
  email: emailValidator
}).required()

const useRedirectToDashboard = () => {
  const navigate = useNavigate()
  const { user } = useAuth()

  useEffect(() => {
    if (user) {
      navigate('/', { replace: true })
    }
  }, [user, navigate])
}

const trackingPayload = { page: 'Login' } as const

const SignInPage = () => {
  usePageTracker('Visits Login Page', trackingPayload)

  const theme = useTheme()

  useRedirectToDashboard()

  const navigate = useNavigate()
  const { getRedirectToQueryParam } = useRedirectToQueryParam((path) => {
    if (path === '/lookup/company') return '/lookup/address'
    return path
  })

  const form = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: yupResolver(schema)
  })
  const signInAtom = useSignIn()
  const [error, setError] = useState<string | ServerError | undefined>(undefined)

  const { control, handleSubmit, formState } = form
  const { errors } = formState

  const handleFormSubmit = async (values: FormValues) => {
    setError(undefined)

    try {
      const response = await signIn(values)

      signInAtom(response.data)

      navigate(getRedirectToQueryParam)
    } catch (error) {
      const axiosError = error as AxiosError<ServerError>

      console.log('axiosError.response?.data', axiosError.response?.data)

      const errorObj = get(axiosError, 'response.data.errors', [])?.[0] as any

      if (errorObj?.msg === 'NO_LONGER_ACCESSIBLE') {
        setError(errorObj.msg)
        return
      }

      const errorMessage = axiosError.response?.data?.msg ?? 'Invalid email or password'
      setError(errorMessage)
    }
  }

  return (
    <>
      <DeleteAlert />
      <SignUpPageLayout sideScreen={<WelcomeSideScreen />}>
        <SunsetWarning />
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(handleFormSubmit)} className="flex flex-col min-h-full">
            <Typography variant="h1">Login</Typography>
            <div style={{ marginTop: 40 }}></div>
            <FormInput
              className="font-medium text-[#515468]"
              control={control}
              name="email"
              type="email"
              label="EMAIL ADDRESS"
              controlId="fieldEmailAddress"
              rules={{ required: true }}
              error={errors.email?.message || formatError(error, 'email')}
            />
            <FormInput
              control={control}
              className="font-medium text-[#515468]"
              name="password"
              label="PASSWORD"
              type="password"
              controlId="fieldPassword"
              rules={{ required: true }}
              error={errors.password?.message || formatError(error, 'password')}
            />
            <Link
              to="/auth/forget-password"
              style={{ textDecoration: 'none', color: theme.palette.primary.main }}
              onClick={() => {
                trackEvent('Forgot password', { page: 'Login' })
              }}
            >
              Forgot your password?
            </Link>

            <LoadingButton
              className="w-full mt-6 min-w-[430px]"
              track={{ event: 'Clicks Log In', page: 'Login' }}
            >
              Continue
            </LoadingButton>

            <div className="mt-[24px]">
              {error ? (
                error === 'NO_LONGER_ACCESSIBLE' ? (
                  <NoLongerAccessible type="sign-in" />
                ) : (
                  <Alert severity="error">{formatError(error)}</Alert>
                )
              ) : null}
            </div>

            <div className="mx-auto text-center text-sm font-medium mt-8 flex flex-row">
              <p>Don&apos;t have an account yet?</p>
              <Link
                to="/auth/sign-up"
                className="mt-0.5 ml-4"
                style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
              >
                Sign up
              </Link>
            </div>
          </form>
        </FormProvider>
      </SignUpPageLayout>
    </>
  )
}

export default SignInPage

import { Alert, AlertTitle, Link } from '@mui/material'

const SunsetWarning = () => {
  return (
    <Alert
      severity="warning"
      style={{
        position: 'absolute',
        top: 30,
        left: '35%',
        border: '1px solid #FFB800',
        borderRadius: 8,
        fontSize: 14,
        fontWeight: 500,
        color: '#010417'
      }}
    >
      <AlertTitle
        style={{
          fontWeight: 700,
          color: '#010417',
          fontSize: 14
        }}
      >
        Notice: Archer is no longer available to the public.
      </AlertTitle>
      For more information about this change, please visit{' '}
      <Link href="https://helloarcher.io/delisting" target="_blank">
        helloarcher.io/delisting
      </Link>
    </Alert>
  )
}

export default SunsetWarning
